<template>
  <div class="flex flex-col">
    <div class="flex flex-col p-4 border rounded">
      <div class="flex space-x-2">
        <input
          type="text"
          v-model="$v.filter.name.$model"
          placeholder="Filter Name"
          :class="{ 'border-red-500': $v.filter.name.$invalid }"
          class="my-2 border rounded px-4 py-2 w-full text-xs appearance-none focus:outline-none bg-transparent"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators"

const tempFilter = {
  name: null,
}

export default {
  name: "new-filter",
  props: ["saved"],
  components: {},
  data() {
    return {
      filter: {
        ...tempFilter,
      },
    }
  },
  validations: {
    filter: {
      name: {
        required,
      },
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {},
    save() {
      if (!this.$v.$invalid) {
        this.$emit("close-modal", { name: this.filter.name })
      }
    },
    closeModal() {
      this.$emit("close-modal", { show: false })
    },
  },
  watch: {
    saved: function(n) {
      if (n) this.filter = { ...this.tempFilter }
    },
  },
}
</script>
